<template>
  <div class="equipment-work-order-wrapper">
    <van-nav-bar
      title="工单"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-work-order-form">
      <span>共{{ total }}条数据</span>
    </div>
    <div class="equipment-work-order-box">
      <van-empty
        v-if="!loading && list.length == 0"
        :image="emptyImage"
        description="暂无数据"
      />
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div
          v-for="item in list"
          :key="item.id"
          class="work-order-item"
          @click="toWorkOrderDetail(item.id)"
        >
          <van-icon class="work-order-link" name="arrow" />
          <span
            class="work-order-interval iconfont"
            :class="`_${item.status} ${iconObj[item.status]}`"
          ></span>
          <div class="work-order-title">
            {{ item.workNo
            }}<span class="work-order-typename">{{ item.name }}</span>
            <span v-if="item.emergencyType == 1" class="work-order-yingji"
              >应急维修</span
            >
          </div>
          <div class="work-order-bottom">
            <span class="work-order-mode"
              ><i class="iconfont iconfont-weixiu"></i
              >{{ item.ticketsTypeName }}</span
            >
            <span class="work-order-lastdate"
              ><i class="iconfont iconfont-ico-timerange"></i
              >{{ item.createTime }}</span
            >
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getWorkOrderList } from "@/api/basics/equipment";
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      iconObj: {
        1: "iconfont-daitijiao1",
        2: "iconfont-daishenpi",
        3: "iconfont-zhihangzhong",
        4: "iconfont-daiyanshoubeifen",
        5: "iconfont-yiguanbi"
      },
      query: {
        page: 1,
        size: 10
      },
      list: [],
      total: 0,
      loading: false,
      finished: false
    };
  },
  created() {},
  methods: {
    // 跳转到工单详情页
    toWorkOrderDetail(id) {
      this.$router.push({
        name: "equipmentWorkOrderDetail",
        params: {
          id
        }
      });
    },
    onLoad() {
      this.getData();
    },
    getData() {
      this.getWorkOrderList();
    },
    getWorkOrderList() {
      this.loading = true;
      getWorkOrderList(this.id, this.query)
        .then(res => {
          console.log("res", res);
          this.loading = false;
          this.list = this.list.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.total = res.total;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-work-order-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-work-order-form {
    height: 56px;
    border-bottom: 1px solid #e1e3e8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    color: #999;
  }
  .equipment-work-order-box {
    flex: 1;
    overflow-y: auto;
    height: 0;
    padding-top: 14px;
    .work-order-item {
      position: relative;
      padding: 0 16px;
      .work-order-link {
        position: absolute;
        right: 16px;
        top: 40%;
        transform: translate3d(0, -50%, 0);
        color: #aeb3c0;
        font-size: 18px;
      }
      .work-order-title {
        color: #3b4664;
        font-size: 14px;
        font-weight: 700;
        .work-order-typename {
          margin-left: 20px;
        }
        .work-order-yingji {
          background-color: rgba(241, 48, 48, 0.1);
          color: #f13030;
          border-radius: 2px;
          padding: 0 2px 1px 2px;
          font-size: 12px;
          margin-left: 5px;
        }
      }
      .work-order-interval {
        position: absolute;
        top: 5px;
        right: 35px;
        font-size: 42px;
        &._1 {
          // 待提交
          color: #64ae08;
        }
        &._2 {
          // 待审批
          color: #3683f4;
        }
        &._3 {
          // 执行中
          color: #dea148;
        }
        &._4 {
          // 待验收
          color: #3683f4;
        }
        &._5 {
          // 已关闭
          color: #aeb3c0;
        }
      }
      .work-order-bottom {
        margin-top: 18px;
        display: flex;
        font-size: 12px;
        align-items: center;
        color: #3b4664;
        i {
          color: #aeb3c0;
          margin-right: 5px;
          font-size: 14px;
        }
        .work-order-mode {
        }
        .work-order-lastdate {
          font-weight: 500;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
